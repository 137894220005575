import React, { useEffect, useState } from 'react';
import About from "./About";
import Category from './categoryList'
import './index.css'
import { connect } from 'react-redux';
import { getSearchProduct, getFilter } from 'actions/buyer';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import BuyerContainer from 'components/buyerContainer';

const SearchResults = (props) => {
  const params = useParams()
  const location = useLocation()
  const { searchProduct, filter } = props;
  const [sortBy, setSortBy] = useState('price')
  const [paramsUrl] = useSearchParams()

  const handleScroll = () => {
    const { index, selected } = props
    if (index === selected) {
      const element = document.getElementById("searchResult")
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    const data = {
      key: decodedURL.search ? decodedURL.search : '',
      company_id: decodedURL.company_id ? decodedURL.company_id : '',
      category_id: decodedURL.category_id ? decodedURL.category_id.split('%2C') : [],
      seller_id: decodedURL.seller_id ? decodedURL.seller_id : '',
      mediType: decodedURL.medicineType ? decodedURL.medicineType : '',
      discount: decodedURL.discount && decodedURL.discount === '10 or more' ? 10 : decodedURL.discount === '20 or more' ? 20 : decodedURL.discount === '50 or more' ? 50 : '',
      page: 1,
      perPage: 12,
      sortBy: decodedURL.sortBy ? decodedURL.sortBy : 'price',
      checkBanner: paramsUrl.get("checkBanner") ? paramsUrl.get("checkBanner") : ''
    }
    setSortBy(decodedURL.sortBy ? decodedURL.sortBy : 'price')
    props.getSearchProduct({ data })
    handleScroll()
    props.getFilter({})
  }, [])

  return (
    <BuyerContainer>
      <div className="app-wrapper" id="searchResult">
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-12 d-none d-sm-block">
              <div className="jr-card jr-full-card ">
                <Category filterFromParent={filter} />
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-12">
              <About
                sortBy={sortBy}
                showAddToCard={!location.pathname.includes('/view-company')}
                dataFromParent={searchProduct}
              />
            </div>
          </div>
        </div>
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { searchProduct, filter } = buyer;
  return { searchProduct, filter }
};

export default connect(mapStateToProps, { getSearchProduct, getFilter })(SearchResults);