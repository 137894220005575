import React, { useState, useEffect } from 'react';
import InfoCard from 'components/InfoCard';
import AppConfig from 'constants/config'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
export var detailCards = [
  {
    color: '#072791',
    icon: 'card-giftcard',
    title: '0',
    subTitle: 'Products'
  },
  {
    color: '#ff9800',
    icon: 'star-outline',
    title: '0',
    subTitle: 'Reviews'
  }
]

const SalesStatistic = (props) => {
  const params = useParams()
  const [cardDetails, setCardDetails] = useState([...detailCards])
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${AppConfig.baseUrl}seller/get_stats`,
      data: {
        seller_id: params.id
      }
    })
      .then((results) => {
        if (results.data.detail) {
          const { detail } = results.data
          let temp = [...cardDetails]
          temp[0].title = detail.active_products
          temp[1].title = detail.ratings
          setCardDetails(temp)
          setLoader(false)
        }
      })
  }, [])

  return (
    <React.Fragment>
      {loader ? (
        <div className="loader-view">
          <CircularProgress />
        </div>
      ) : (
        cardDetails.map((data, index) => (
          <div className="col-5 infoCard" key={index}>
            <InfoCard data={data} />
          </div>
        ))
      )}
    </React.Fragment>
  );

}

export default SalesStatistic;
