import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import CourselProduct from 'components/BCourselProduct'
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import './index.css'
import './secondaryHeader.css'
import FeaturedBCoursel from './FeaturedBCoursel'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { getFeaturedProductList } from 'actions/buyer';
import { getProductsForYou } from 'actions/buyer';
import { getPromotionsListing } from 'actions/buyer'
import { Helmet } from "react-helmet";
import BuyerContainer from 'components/buyerContainer';
import { useNavigate } from 'react-router';


const Dashboard = (props) => {
  const navigate = useNavigate()
  const { featured, dealsArr, fastMovingArr, jumboArr, productsForYou, promotionsList } = props;
  const [activeIndex2, setActiveIndex2] = useState(0)
  const [dashboardModal, setDashboardModal] = useState(false)
  const [bannerData, setBannerData] = useState([])
  const [animating, setAnimating] = useState(false)

  function onExiting2() {
    setAnimating(true);
  }

  function onExited2() {
    setAnimating(false);
  }

  function next2() {
    if (animating) return;
    const nextIndex = activeIndex2 === bannerData.length - 1 ? 0 : activeIndex2 + 1;
    setActiveIndex2(nextIndex);
  }

  function previous2() {
    if (animating) return;
    const nextIndex = activeIndex2 === 0 ? bannerData.length - 1 : activeIndex2 - 1;
    setActiveIndex2(nextIndex);
  }

  function goToIndex2(newIndex) {
    if (animating) return;
    setActiveIndex2(newIndex);
  }

  useEffect(() => {
    props.getFeaturedProductList({})
    props.getPromotionsListing({})
    const isUserLoggedIn = localStorage.getItem("buyer_token");
    if (isUserLoggedIn !== null) {
      props.getProductsForYou({})
    }
    if (isUserLoggedIn == null) {
      setDashboardModal(true)
      setTimeout(() => {
        setDashboardModal(false)
      }, 4000)
    }
    setTimeout(() => {
      next2()
    }, 5000)
  }, [])

  const handleClose = () => {
    setDashboardModal(false)
  }

  const scrollToDeals = (e) => {
    const element = document.getElementById("dealsOfTheDay");
    element.scrollIntoView({ behavior: "smooth" });
  }

  const scrollToFast = () => {
    const element = document.getElementById("fastMovingProducts");
    element.scrollIntoView({ behavior: "smooth" });
  }
  const toSearch1 = () => {
    navigate('/search-results/category/search=&company=&seller=&discount=')
  }
  const toSearch = (type) => {
    navigate(`/search-results/category/medicineType=${type}&search=&company=&seller=&discount=`)
  }

  const redirectTo = (e) => {
    if (e !== '') {
      window.open(`${e}`, '_blank');
    }
  }

  useEffect(() => {
    setBannerData(promotionsList)
  }, [promotionsList])

  return (
    <BuyerContainer>
      <div className='dashboard animated slideInUpTiny animation-duration-3 scrollBehavior'>
        <Helmet>
          <title>B2B Online Wholesale Medicine Supply For Pharmacy - Medimny</title>
          <meta name="title" content="B2B Online Wholesale Medicine Supply For Pharmacy - Medimny" />
          <meta name="description" content="Medimny is a B2B E-Commerce platform for Pharmaceutical Products for Distributors, Stockists and Retail Drug Stores serving entire nation." />
        </Helmet>
        <div className="secondaryHeaderWrapper">
          <div className="secondaryHeaderMain">
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", paddingLeft: "30px", float: "left" }}>
              <span onClick={scrollToDeals} className="capsuleTwo">Deals Of The Day</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={scrollToFast} className="capsuleTwo capsuleTwoMaxLength">Fast Moving Products</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={toSearch1} className="capsuleTwo">Show All Deals</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={() => toSearch('Ethical Branded')} className="capsuleTwo capsuleTwoMaxLength">{'Ethical Branded & PCD'}</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={() => toSearch('Cool Chain')} className="capsuleTwo">Cool Chain</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={() => toSearch('Generic')} className="capsuleTwo">{'Generic & Surgical'}</span>
            </h5>
            <h5 className="dealsOfTheDay" style={{ cursor: "pointer", float: "left" }}>
              <span onClick={() => toSearch('Short Expiry')} className="capsuleTwo">{'Short Expiry'}</span>
            </h5>
            <h5 className="sellOnMedideals" style={{ paddingRight: "30px", float: "right" }}>
              <i class="zmdi zmdi-phone ml-1 mr-1"></i>
              <span style={{ fontWeight: 'bold' }}>+91 9321927004</span>
            </h5>
          </div>
        </div>

        {bannerData && bannerData.length > 0 ?
          <Carousel
            activeIndex={activeIndex2}
            next={next2}
            previous={previous2}
            className='home_banner'
          >
            <CarouselIndicators items={bannerData} activeIndex={activeIndex2} onClickHandler={goToIndex2} />
            {
              bannerData.map((banner, i) => {
                // Add the key dynamically to the URL
                const url = new URL(banner.redirect_url);
                url.searchParams.set("checkBanner", banner._id.toString());

                return <CarouselItem
                  key={i}
                  onExiting={onExiting2}
                  onExited={onExited2}>
                  <img style={{ cursor: "pointer" }} className="app-logo-img" src={banner.image_banner} alt={banner.name} onClick={(e) => redirectTo(decodeURIComponent(url))} />
                </CarouselItem>
              })
            }
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous2} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next2} />
          </Carousel>
          :
          null
        }
        <div style={{ textAlign: 'center' }}>
          <h1 className="title text-primary mb-0 mt-3 font-weight-bold">India's Leading B2B E-Commerce of Pharmaceutical Products Hub</h1>
        </div>
        <div className="app-wrapper pt-3">
          <Row className='caroselColor'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {featured && featured.length >= 1 ?
                <div id="featured_carousel">
                  <FeaturedBCoursel
                    key={'featured'}
                    data={featured}
                    identifier={'featured'}
                  />
                </div>
                :
                null
              }
              {dealsArr.length >= 1 ?
                <div id="dealsOfTheDay">
                  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" style={{ marginBottom: '15px' }}>
                    <h2 className="title mb-0 mb-sm-0">Deals of the day</h2>
                  </div>
                  <CourselProduct
                    key={'dealsArr'}
                    data={dealsArr}
                    identifier={'dealsArr'}
                  />
                </div>
                :
                null
              }
              {fastMovingArr.length >= 1 ?
                <div id="fastMovingProducts">
                  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" style={{ marginBottom: '15px' }}>
                    <h2 className="title mb-0 mb-sm-0">Fast moving products</h2>
                  </div>
                  <CourselProduct
                    key={'fastMovingArr'}
                    data={fastMovingArr}
                    identifier={'fastMovingArr'}
                  />
                </div>
                :
                null
              }
              {jumboArr && jumboArr.length >= 1 ?
                <div id="jumboProducts">
                  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" style={{ marginBottom: '15px' }}>
                    <h2 className="title mb-0 mb-sm-0">Jumbo deals</h2>
                  </div>
                  <CourselProduct
                    key={'jumboProducts'}
                    data={jumboArr}
                    identifier={'jumboArr'}
                  />
                </div>
                :
                null
              }
              {productsForYou && productsForYou.visitedProduct && productsForYou.visitedProduct.length >= 1 ?
                <div id="productsForYou">
                  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" style={{ marginBottom: '15px' }}>
                    <h2 className="title mb-0 mb-sm-0">Products for you</h2>
                  </div>
                  <CourselProduct
                    key={'CourselProductsForYou'}
                    data={productsForYou.visitedProduct}
                    identifier={'CourselProductsForYou'}
                  />
                </div>
                :
                null
              }
            </Col>

          </Row>
        </div>

        <Dialog open={dashboardModal} onClose={handleClose} fullWidth={true}>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogTitle className="" id="alert-dialog-title" style={{ textAlign: "center", paddingBottom: '0px' }}>
              <h1 className="mt-4 font-weight-bold">
                Welcome to India’s Fastest growing B2B marketplace for
                Pharmaceutical Products.
              </h1>
            </DialogTitle>
            <h1 style={{ textAlign: "center" }}>Welcome to <span style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>"Medimny"</span></h1>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" autoFocus> Close </Button>
          </DialogActions>
        </Dialog>
      </div>
    </BuyerContainer>
  );
}


const mapStateToProps = ({ buyer }) => {
  const { featured, dealsArr, fastMovingArr, jumboArr, productsForYou, promotionsList } = buyer;
  return { featured, dealsArr, fastMovingArr, jumboArr, productsForYou, promotionsList }
};

export default connect(mapStateToProps, { getFeaturedProductList, getProductsForYou, getPromotionsListing })(Dashboard);