import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Widget from "components/Widget/index";
import CourselProduct from 'components/BCourselProduct'
import { Col, Row } from 'reactstrap'
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';
import ProductTab from 'components/bProductDetailsTab';
import helpertFn from 'constants/helperFunction';
import { connect } from 'react-redux';
import { getProductDetails } from 'actions/buyer';
import './index.css'
import { useParams } from "react-router-dom";
import BuyerContainer from 'components/buyerContainer';
import { useSearchParams } from 'react-router-dom';

const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const BProductDetails = (props) => {
  const params = useParams()
  const [image, setImage] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const [productData, setProductData] = useState([])
  const [imageData, setImageData] = useState([])
  const [paramsUrl] = useSearchParams()

  function next() {
    const nextIndex = activeIndex === imageData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex)
  }

  function previous() {
    const nextIndex = activeIndex === 0 ? imageData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex)
  }

  useEffect(() => {
    if (paramsUrl.get("checkBanner")) {
      props.getProductDetails({inventory_id: params.id, checkBanner: paramsUrl.get("checkBanner")})
    }else{
      props.getProductDetails({ inventory_id: params.id })
    }
  }, [])

  const handleScroll = () => {
    const { index, selected } = props
    if (index === selected) {
      const element = document.getElementById("productDetails")
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleImageChange = (product) => {
    setImage(product.img)
  }

  const getImage = () => imageData.map((product, index) => <div key={index} className='col-sm-3 col-md-3 col-lg-3 sliderImageDiv'>
    <img src={`${helpertFn.productImg(product.img)}`} onClick={(e) => handleImageChange(product)} style={{ padding: '3.5px 3.5px 0px 0px' }} onError={addDefaultSrc} alt="product" />
  </div>)

  useEffect(() => {
    if (props.productData && props.productData[0]) {
      let images = []
      for (const dataOne of props.productData[0].Product.images) {
        images.push({
          img: dataOne,
          featured: true,
        })
      }
      setImage(props.productData[0].Product.images.length > 0 ? props.productData[0].Product.images[0] : logo)
      setProductData(props.productData)
      setImageData(images)
    }
    handleScroll()
  }, [props.productData])

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const { relatedProducts } = props;

  return (
    <BuyerContainer>
      <div className='app-wrapper' id="productDetails">
        <Widget styleName="jr-card-full" >
          <div className="row mb-0" >
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 box">
              <div className="productSlider ml-3 mt-3">
                <Carousel
                  autoPlay={false}
                  indicators={true}
                  activeIndex={activeIndex}
                  next={next}
                  interval={false}
                  previous={previous}
                  className='itemImageProductDetail'
                >
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} className="arrows" />
                  {
                    imageData.map((product, index) => {
                      return (
                        <CarouselItem key={index}>
                          <div className='d-flex'>
                            {getImage()}
                          </div>
                        </CarouselItem>
                      )
                    })
                  }
                  <CarouselControl direction="next" directionText="Next" onClickHandler={next} className="arrows" />
                </Carousel>
              </div>
              <div className="jr-slick-slider-lt-thumb content" style={{ margin: 'auto', width: '60%', height: 'auto' }} >
                <img
                  className={'fadein img-fluid'} src={`${helpertFn.productImg(image)}`}
                  alt="..."
                  onError={addDefaultSrc}
                />
              </div>
              {productData && productData[0] && productData[0].Product && productData[0].Product.description &&
                <div className="row mb-0 mt-4 w-60" >
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 box">
                    <div className="jr-slick-slider-lt-thumb content" style={{ margin: 'auto', width: '60%', height: 'auto' }}>
                      <p style={{ color: "#202790", marginBottom: "1px" }}>Description :</p>
                      <span>{productData && productData[0] && productData[0].Product && productData[0].Product.description}</span>
                    </div>
                  </div>
                </div>}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <ProductTab />
            </div>
          </div>
        </Widget>
        {
          relatedProducts && relatedProducts.length > 0 ?
            <Row className='caroselColor'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
                  <h2 className="title mb-3 mb-sm-0">Related Products</h2>
                </div>
                <div className="mb-3 mt-3 text-left"></div>
                <CourselProduct
                  key={'relatedProducts'}
                  data={relatedProducts}
                />
              </Col>
            </Row>
            :
            null
        }
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { data, otherSellers, relatedProducts, productData } = buyer;
  return { data, otherSellers, relatedProducts, productData }
};

export default connect(mapStateToProps, { getProductDetails })(BProductDetails);



