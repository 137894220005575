import React, { useState, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import DeliveryAddress from './DeliveryAddress';
import { connect } from 'react-redux';
import { getUserDetail } from 'actions'
import { getCartDetails } from 'actions/buyer'
import { getMediWallet } from 'actions/buyer'
import { removeFromCart, emptyCart } from 'actions/buyer'
import { addToCart } from 'actions/buyer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MyCartModalError from './MyCartModalError'
import { FormGroup, Col, Row, Label, Input } from 'reactstrap';
import Radio from '@mui/material/Radio';
import { NotificationManager } from 'react-notifications';
import { NavLink, useNavigate } from 'react-router-dom';
import AxiosRequest from 'sagas/axiosRequest'
import CartItems from './CartItems'
import axios from 'axios'
import AppConfig from 'constants/config'
import moment from 'moment';
import './index.css'
import BuyerContainer from 'components/buyerContainer';

const borderEthical = {
  border: '2px solid #ff7000',
  borderRadius: '8px',
  padding: '5px',
  c: '#ff7000'
}
const borderCoolChain = {
  border: '2px solid #0b68a8',
  borderRadius: '8px',
  padding: '5px',
  c: '#0b68a8'
}
const borderGeneric = {
  border: '2px solid  #038d0e',
  borderRadius: '8px',
  padding: '5px',
  c: '#038d0e'
}
const fontS = {
  fontWeight: 'bolder',
  marginTop: '5px'
}

const MyCart = (props) => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [errorData, setErrorData] = useState([])
  const [acceptModal, setAcceptModal] = useState(false)
  const [successData, setSuccessData] = useState([])
  const [minOrderError, setMinOrderError] = useState(false)
  const [open, setOpen] = useState(false)
  const [globalCod, setGlobalCod] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [individualPop, setIndividualPop] = useState(false)
  const [individualSeller, setIndividualSeller] = useState('')
  const [medicinePop, setMedicinePop] = useState(false)
  const [mediTypeData, setMediTypeData] = useState('')
  const [mediData, setMediData] = useState('')
  const [ethicalData, setEthicalData] = useState('')
  const [coolChainData, setCoolChainData] = useState('')
  const [genericData, setGenericData] = useState('')
  const [deliChrg, setDeliChrg] = useState(0)
  const [globalDeliveryCrg, setGlobalDeliveryCrg] = useState('')
  const [globalBnpl, setGlobalBnpl] = useState('')
  const [otpModel, setOtpModel] = useState(false)
  const [otp, setOtp] = useState('')
  const [set, setSet] = useState(false)
  const [otpId, setOtpId] = useState('')
  const [bnplBal, setBnplBal] = useState('')
  const [openNewPopup, setOpenNewPopup] = useState(false)
  const [LicenseErr, setLicenseErr] = useState(false)
  const [totalDeliverd, setTotalDeliverd] = useState(0)
  const [totalOpenCOD, setTotalOpenCOD] = useState(0)
  const [codTotalAmount, setCodTotalAmount] = useState(0)
  const [minOrderErrorIndividual, setMinOrderErrorIndividual] = useState(false)
  const [checkOutVal, setChekOutVal] = useState()
  const [prodcheckOutVal, setProdChekOutVal] = useState()

  const asAdmin = localStorage.getItem('asAdmin')

  const handleRadioChange = event => {
    setSelectedValue(event.target.value)
  };

  const getInitialData = async () => {
    try {
      const isUserLoggedIn = localStorage.getItem("buyer_token");
      if (isUserLoggedIn !== null) {
        let data = {
          page: 1,
          perPage: 10,
          filter: '',
          month: '',
          year: ''
        }
        props.getMediWallet({ data })
        props.getCartDetails({})
      } else {
      }

      const result = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}users/getCodService`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        }
      })
      setTotalDeliverd(Number(result && result.data && result.data.totalDeliverd ? result.data.totalDeliverd : 0))
      setTotalOpenCOD(Number(result && result.data && result.data.totalOpenCOD ? result.data.totalOpenCOD : 0))
      setCodTotalAmount(Number(result && result.data && result.data.codTotalAmount ? result.data.codTotalAmount : 0))

      const configResult = await axios({
        method: 'post',
        url: `${AppConfig.baseUrl}systemConfig/getAllSystemConfigList`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        }
      })
      if (configResult.data && configResult.data.detail && !configResult.data.error) {
        configResult.data.detail.map((data) => {
          if (data.key === "globalCod") {
            setGlobalCod(data)
          }
          if (data.key === "globalBnpl") {
            setGlobalBnpl(data)
          }
          if (data.key === "delivery_charge") {
            setGlobalDeliveryCrg(data)
          }
        })
      }
      const catResult = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}medicine/listMediCategory`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        }
      })
      if (catResult.data && catResult.data.data && !catResult.data.error) {
        catResult.data.data.map(async (data) => {
          if (data.name === 'Ethical branded') {
            setEthicalData(data)
          }
          if (data.name === 'Cool chain') {
            setCoolChainData(data)
          }
          if (data.name === 'Generic') {
            setGenericData(data)
          }
        })
        setMediData(catResult.data.data)
      }
      const bnplResult = await axios({
        method: 'post',
        url: `${AppConfig.baseUrl}bnpl/getBnplBal`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        }
      })
      if (bnplResult.data && bnplResult.data.data && !bnplResult.data.error) {
        setBnplBal(bnplResult.data.data.availableLimit)
      }
    } catch (error) {
    }
  }


  useEffect(() => {
    getInitialData()
  }, [])

  const handleChanged = (e) => {
    setOtp(e.target.value)
  }


  const handleRemoveFromCart = (index) => {
    const isUserLoggedInn = localStorage.getItem("buyer_token");
    if (isUserLoggedInn !== null) {
      props.removeFromCart({ inventory_id: index })
    } else {
    }
  }

  const handleMedicineOrder = async () => {
    setIsLoading(true)
    let data = {
      type: selectedValue,
      seller: mediTypeData ? mediTypeData.ID : '',
      medicineId: mediTypeData ? mediTypeData.medicineId : '',
      ordered_from: "web"
    }
    let bulkInven = props.cartDetails.detail.filter(e => (e.Inventory.isBulk === true && mediTypeData.medicineId === e.Inventory.medicineType_id && mediTypeData.ID === e.Inventory.user_id));
    let isBulk = bulkInven.length > 0 ? 'bulk' : '';
    let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'order/createOrder', '', data)
    if (result.data.error) {
      if (result.data && result.data.data) {
        setOpenModal(true)
        setErrorData(result.data.data)
        result.data?.data && result.data.data.map((val) => {
          if (val.productName && val.productName === "License Related") {
            setLicenseErr(true)
          }
        })
        setIsLoading(false)
      } else {
        setIsLoading(false)
        NotificationManager.error(result.data.title);
        handleOnlineModalClose()
      }
    }
    else {
      if (result.data.token) {
        window.location.assign(`${AppConfig.baseUrl}payOnline/${result.data.token}?type=${isBulk}`)
      } else {
        NotificationManager.success(result.data.title)
        props.getUserDetail({ userType: "buyer" })
        let orderIds = result.data.detail.map(data => {
          return data.order_id
        }).join(', ');
        setAcceptModal(true)
        setSuccessData(orderIds)
        setIndividualSeller('')
        setIsLoading(false)
        setIndividualPop(false)
        setMedicinePop(false)
        setDeliChrg(0)
        setOpenNewPopup(false)
      }
    }
  }

  const handleOnlineOrder = async () => {
    setIsLoading(true)
    let data = { type: selectedValue, seller: individualSeller && individualSeller.ID, ordered_from: "web" };
    let bulkInven = [];
    let isBulk = '';
    if (individualSeller && individualSeller.ID) {
      bulkInven = props.cartDetails.detail.filter(e => (e.Inventory.isBulk === true && individualSeller.ID === e.Inventory.user_id));
    } else {
      bulkInven = props.cartDetails.detail.filter(e => (e.Inventory.isBulk === true));
    }
    isBulk = bulkInven.length > 0 ? 'bulk' : '';
    if (selectedValue === 'BNPL') {
      data.otpId = otpId;
      data.otp = otp
    }
    let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'order/createOrder', '', data)
    if (result.data.error) {
      if (!result.data.bnpl) {
        if (!result.data.data) {
          NotificationManager.error(result.data.title);
        } else {
          setOpenModal(true)
          setErrorData(result.data.data)
        }
      } else {
        NotificationManager.error(result.data.title);
        handleOnlineModalClose()
      }
      setIsLoading(false)
    } else {
      if (result.data.token) {
        window.location.assign(`${AppConfig.baseUrl}payOnline/${result.data.token}?type=${isBulk}`)
      } else {
        NotificationManager.success(result.data.title)
        props.getUserDetail({ userType: "buyer" })
        let orderIds = result.data.detail.map(data => {
          return data.order_id
        }).join(', ');
        setAcceptModal(true)
        setSuccessData(orderIds)
        setIndividualSeller('')
        setIsLoading(false)
        setIndividualPop(false)
        setDeliChrg(0)
        setOpenNewPopup(false)
      }
    }
    handleCloseOtpModel();
    handleOnlineModalClose();
  }

  const handleOnlineModal = () => {
    setOpen(true)
  }

  const handleOnlineModalClose = async () => {
    setOpen(false)
    setIndividualPop(false)
    setDeliChrg(0)
    setSelectedValue('')
    setIndividualSeller('')
    setMedicinePop(false)
    setOpenNewPopup(false)
  }

  const handleOpenModal = async () => {
    setIsLoading(true)
    let data = { type: selectedValue, ordered_from: "web" }
    let bulkInven = props.cartDetails.detail.filter(e => (e.Inventory.isBulk === true));
    let isBulk = bulkInven.length > 0 ? 'bulk' : '';
    let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'order/createOrder', '', data)
    if (result.data.error) {
      if (result.data?.data) {
        setOpenModal(true)
        setErrorData(result.data.data)
        setIsLoading(false)
      } else {
        NotificationManager.error(result.data.title);
        handleOnlineModalClose()
      }

    } else {
      if (result.data.token) {
        window.location.assign(`${AppConfig.baseUrl}payOnline/${result.data.token}?type=${isBulk}`)
      } else {
        NotificationManager.success(result.data.title)
        let orderIds1 = result.data.detail.map(data => {
          return data.order_id
        }).join(', ');
        setAcceptModal(true)
        setSuccessData(orderIds1)
        setIndividualSeller('')
        setIsLoading(false)
        setIndividualPop(false)
        setDeliChrg(0)
        setOpenNewPopup(false)
      }
    }
  }

  const handleClose = () => {
    setOpenModal(!openModal)
    setOpen(false)
    setIndividualPop(false)
    setDeliChrg(0)
    setIndividualSeller('')
    setMedicinePop(false)
  }

  const yesShowCart = async () => {
    errorData.map((dataOne) => {
      if (dataOne.type === "update") {
        let data = {
          cart_details: [{
            inventory_id: dataOne.inventory_id,
            quantity: dataOne.quantity
          }]
        }
        props.addToCart({ data })
      }
    })
    let removeArr = [];
    if (removeArr.length > 0) {
      const result = await axios({
        method: 'post',
        url: `${AppConfig.baseUrl}cart/removeAll`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        },
        data: { inventory: removeArr }
      })
      if (result.data.error) {
      } else {
        props.getCartDetails({})
      }
    }
    handleClose()
  }

  const handleCloseOtpModel = () => {
    setOtpModel(false)
    setOtp('')
    setOtpId('')
    setIsLoading(false)
    setSet(false)
  }

  const handleAcceptClose = () => {
    setAcceptModal(false)
    navigate('/', { state: '/MyCart' })
  }

  const handleMinOrderErrorClose = () => {
    setMinOrderError(false)
    setMinOrderErrorIndividual(false)
    setIsLoading(false)
  }

  const minOrderErrorProceed = async () => {
    handleOpenModal()
    setMinOrderError(false)
  }

  const individualCheckout = (data) => {
    let inventoryFor = []
    let { cartDetails, users } = props;
    let totalForDeli = 0;
    if (data.coolChain > 0 && data.coolChain > coolChainData.minOrder) {
      totalForDeli = totalForDeli + 1;
    }
    if (data.ethical > 0 && data.ethical > ethicalData.minOrder) {
      totalForDeli = totalForDeli + 1;
    }
    if (data.generic > 0 && data.generic > genericData.minOrder) {
      totalForDeli = totalForDeli + 1;
    }
    let delichrg = users && users.isDeliveryCharge === true ? totalForDeli * Number(globalDeliveryCrg.value) : 0;
    cartDetails && cartDetails.detail && cartDetails.detail.length > 0 && cartDetails.detail.map((dataOne, index) => {
      if (data.ID === dataOne.Seller._id) {
        inventoryFor.push([index] = dataOne.Inventory._id)
      }
    })
    let bnplOption = false;
    let cool = true;
    let ethic = true;
    let gene = true;
    if (data.coolChain > 0 && data.coolChain < 5000) {
      cool = false;
    }
    if (data.ethical > 0 && data.ethical < 5000) {
      ethic = false;
    }
    if (data.generic > 0 && data.generic < 5000) {
      gene = false;
    }
    if (cool && ethic && gene) {
      bnplOption = true
    } else {
      bnplOption = false
    }
    let data1 = { ...data, bnplOption: bnplOption }
    setDeliChrg(delichrg)
    setIndividualPop(true)
    setIndividualSeller(data1)
    const calVal = (data1.price || 0) + (data1.gst || 0)
    console.log("ddddddddddddd1456", calVal)
    setChekOutVal(calVal)

  }

  const medicineFunction1 = async (data, key, value) => {
    data.key = (key === 'ethical') ? 'Ethical branded' : (key === 'cool') ? 'Cool chain' :
      (key === 'generic') ? 'Generic' : 'Others';
    let prodCod = value && value.filter(e => e.isPrepaid === true);
    let invenCod = value && value.filter(e => e.prepaidInven === true || e.isBulk === true);
    let med = mediData && mediData.filter(e => e.name === data.key);
    data.medicineId = med[0]._id;
    data.showCod = med[0].onlyPrepaid;
    data.showCodForProd = prodCod.length > 0 ? true : false;
    data.showCodForInven = invenCod.length > 0 ? true : false;
    setMediTypeData(data)
    setMedicinePop(true)
    let value3 = (
      data.key === 'Cool chain' ? data.coolChain + data.coolChainGst :
        data.key === 'Ethical branded' ? data.ethical + data.ethicalGst :
          data.key === 'Generic' ? data.generic + data.genericGst : 0
    )

    let value1 = (
      data.key === 'Cool chain' ? data.coolChain + data.coolChainGst :
        data.key === 'Ethical branded' ? data.ethical + data.ethicalGst :
          data.key === 'Generic' ? data.generic + data.genericGst : 0
    )

    let calculatedVal = individualDeliveryChrg > 0 ? (value3 + Number(individualDeliveryChrg || 0)) : value1
    console.log("ddddddddddddd145", calculatedVal)
    setProdChekOutVal(calculatedVal)
  }

  const medicineFunction = async (data, key, value) => {
    // Combine "Generic" into "Ethical branded"
    data.key = (key === 'ethical' || key === 'generic') ? 'Ethical branded' :
      (key === 'cool') ? 'Cool chain' : 'Others';

    // Prepaid and Inventory filtering (same as before)
    let prodCod = value && value.filter(e => e.isPrepaid === true);
    let invenCod = value && value.filter(e => e.prepaidInven === true || e.isBulk === true);

    // Find matching medicine data
    let med = mediData && mediData.filter(e => e.name === data.key);
    data.medicineId = med[0]._id;
    data.showCod = med[0].onlyPrepaid;
    data.showCodForProd = prodCod.length > 0;
    data.showCodForInven = invenCod.length > 0;

    setMediTypeData(data);
    setMedicinePop(true);

    // Total value calculation (combining generic into ethical)
    let value3 = (
      data.key === 'Cool chain' ? data.coolChain + data.coolChainGst :
        data.key === 'Ethical branded' ? (data.ethical + data.ethicalGst + data.generic + data.genericGst) :
          0
    );

    let value1 = value3;

    let calculatedVal = individualDeliveryChrg > 0 ?
      (value3 + Number(individualDeliveryChrg || 0)) :
      value1;

    console.log("Calculated Value:", calculatedVal);
    setProdChekOutVal(calculatedVal);
  }


  const activeButton = (data) => {
    let cool = true;
    let ethic = true;
    let gene = true;
    if (data.coolChain > 0 && data.coolChain < coolChainData.minOrder) {
      cool = false;
    }
    if (data.ethical > 0 && data.ethical < ethicalData.minOrder) {
      ethic = false;
    }
    // if (data.generic > 0 && data.generic < genericData.minOrder) {
    //   gene = false;
    // }
    if (cool && ethic && gene) {
      return true
    } else {
      return false
    }
  }

  const short = (type, data) => {
    if (type === 'coolChain' && data.coolChain > 0 && data.coolChain < coolChainData.minOrder) {
      return true
    }
    if (type === 'ethicalBranded' && data.ethical > 0 && data.ethical < ethicalData.minOrder) {
      return true
    }
    if (type === 'generic' && data.generic > 0 && data.generic < genericData.minOrder) {
      return true
    }
    return false;
  }

  const getOtp = async () => {
    setSet(true)
    let data = {
      type: 'Order',
      action: 'OTP for BNPL order.',
      counter: 1,
      typeId: null
    }
    const result = await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}systemActivity/addActivity`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('buyer_token')
      },
      data: data
    })
    if (result.data.error) {
      NotificationManager.error(result.data.title)
    } else {
      setOtpId(result.data.id)
      NotificationManager.success(result.data.title);
    }
  }

  const isUserLoggedIn = localStorage.getItem("buyer_token");
  const { cartDetails } = props;
  const threeMonth = (new Date(moment().add(3, "M"))).getTime();
  let singleItemTotalGST = 0
  let AllItemsTotalPrice = 0
  let AllItemsTotalGST = 0
  let GST = 0
  let one = [];
  let j = 1;
  let discountMinus = 0
  let priceAfterDiscount = 0
  let newGST = 0
  let totalDiscountMinus = 0
  let finalGST = 0
  let data = []
  let sellerDetails = [];
  let total = 0;
  let singleItemTotalPrice;
  const { users } = props;
  let individualDeliveryChrg = users && users.isDeliveryCharge === true ? Number(globalDeliveryCrg.value) : 0;
  const isLogined = localStorage.getItem("buyer_token");
  let removeInventoryLists = []
  let isCoolChain;
  let isPrepaid = [];
  let prepaidInvenAll;

  if (isLogined) {
    cartDetails && cartDetails.detail && cartDetails.detail.length > 0 && cartDetails.detail.map((dataOne, index) => {
      data.push({
        image: dataOne.Inventory.product_id.images[0],
        title: dataOne.Inventory.product_id.name,
        time: dataOne.Inventory.updatedAt,
        seller_id: dataOne.Seller.company_name,
        sellerID: dataOne.Seller._id,
        sellerStatus: dataOne.Seller.user_status,
        sellerVac: dataOne.Seller.vaccation.vaccationMode,
        price: dataOne.Inventory.PTR,
        gstPercentage: dataOne.Inventory.product_id.gst.value,
        quantity: dataOne.cart_details.quantity,
        stock: 0,
        inventory_id: dataOne.Inventory._id,
        discount: dataOne.Discount ? dataOne.Discount : {},
        discountPercentage: dataOne.Discount.discount_per,
        min_order_quantity: dataOne.Inventory.min_order_quantity,
        max_order_quantity: dataOne.Inventory.max_order_quantity,
        user_id: dataOne.Inventory.user_id,
        inventory: dataOne.Inventory ? dataOne.Inventory : {},
        discountProduct: dataOne.DiscountProduct ? dataOne.DiscountProduct : {},
        isPrepaid: dataOne.Inventory.product_id.isPrepaid ? dataOne.Inventory.product_id.isPrepaid : false,
        prepaidInven: dataOne.Inventory.prepaidInven ? dataOne.Inventory.prepaidInven : false,
        isBulk: dataOne.Inventory.isBulk ? dataOne.Inventory.isBulk : false
      })
    })


    let totalItemsInCart = []
    cartDetails && cartDetails.detail && cartDetails.detail.length > 0 && cartDetails.detail.map((dataOne, index) => {
      totalItemsInCart.push({
        user_id: dataOne.Inventory.user_id,
        price: dataOne.Discount && dataOne.Discount.type === "Discount" && dataOne.Discount.discount_per ?
          (dataOne.Inventory.ePTR) * dataOne.cart_details.quantity
          :
          dataOne.Discount && (dataOne.Discount.type === "Same" || dataOne.Discount.type === "SameAndDiscount") ?
            ((dataOne.cart_details.quantity) / (dataOne.Discount.discount_on_product.purchase) * (dataOne.Discount.discount_on_product.bonus) + dataOne.cart_details.quantity) * dataOne.Inventory.ePTR
            :
            dataOne.Inventory.ePTR * dataOne.cart_details.quantity,
        seller_name: dataOne.Seller.company_name,
        inventory_id: dataOne.Inventory._id,
        discount_type: dataOne.Discount.type,
        discount_per: dataOne.Discount && dataOne.Discount.discount_per ? dataOne.Discount.discount_per : "noPercentage",
        gstValue: dataOne.Discount && (dataOne.Discount.type === "Same" || dataOne.Discount.type === "SameAndDiscount") ?
          (dataOne.Inventory.ePTR * ((dataOne.cart_details.quantity) / (dataOne.Discount.discount_on_product.purchase) * (dataOne.Discount.discount_on_product.bonus) + dataOne.cart_details.quantity)) / 100 * dataOne.Inventory.product_id.gst.value
          :
          (dataOne.Inventory.ePTR * dataOne.cart_details.quantity) / 100 * dataOne.Inventory.product_id.gst.value,
        mediType: dataOne.Inventory.medicineTypeName,
        isPrepaid: dataOne.Inventory.product_id.isPrepaid ? dataOne.Inventory.product_id.isPrepaid : false,
        prepaidInven: dataOne.Inventory.prepaidInven ? dataOne.Inventory.prepaidInven : false,
        isBulk: dataOne.Inventory.isBulk ? dataOne.Inventory.isBulk : false
      })
    })

    isCoolChain = totalItemsInCart.filter(e => e.mediType === 'Cool chain');
    prepaidInvenAll = totalItemsInCart.filter(e => (e.prepaidInven === true || e.isBulk === true));
    var holder = {};
    totalItemsInCart.forEach(function (d) {
      if (holder.hasOwnProperty(d.seller_name)) {
        holder[d.seller_name] = holder[d.seller_name] + d.price;
      } else {
        holder[d.seller_name] = d.price;
      }
    });
    isPrepaid = totalItemsInCart.filter(e => e.isPrepaid === true);

    var deliveryCal = [];

    for (var prop in holder) {
      deliveryCal.push({ seller_name: prop, price: holder[prop] });
    }

    totalItemsInCart.map(each => {
      let filteredArray = totalItemsInCart.filter(eC => eC.user_id === each.user_id)
      let sum = filteredArray.reduce((acc, current) => acc + current.price, 0)
      let gst2 = filteredArray.reduce((acc, current) => acc + current.gstValue, 0)

      let genericC = totalItemsInCart.filter(eC => (eC.user_id === each.user_id &&
        (eC.mediType === 'Generic' || eC.mediType === 'OTC' || eC.mediType === 'Surgical')));
      let coolChainC = totalItemsInCart.filter(eC => (eC.user_id === each.user_id && eC.mediType === 'Cool chain'));
      let ethicalC = totalItemsInCart.filter(eC => (eC.user_id === each.user_id && (
        eC.mediType === 'Ethical branded' || eC.mediType === 'Others'
        || eC.mediType === 'Generic' || eC.mediType === 'OTC' || eC.mediType === 'Surgical'
      )));

      // let genericP = genericC.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);
      let coolChainP = coolChainC.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);
      let ethicalP = ethicalC.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);

      // let genericGst = genericC.reduce((accumulator, currentValue) => accumulator + currentValue.gstValue, 0);
      let coolChainGst = coolChainC.reduce((accumulator, currentValue) => accumulator + currentValue.gstValue, 0);
      let ethicalGst = ethicalC.reduce((accumulator, currentValue) => accumulator + currentValue.gstValue, 0);
      let prepaid = totalItemsInCart.filter(e => (e.user_id === each.user_id && e.isPrepaid === true));
      let prepaidInven = totalItemsInCart.filter(e => (e.user_id === each.user_id && (e.prepaidInven === true || e.isBulk === true)));
      let obj = {}
      obj.user_id = each.user_id;
      obj.seller_name = each.seller_name;
      obj.price = sum;
      obj.gst1 = gst2;
      // obj.generic = genericP;
      obj.coolChain = coolChainP;
      obj.ethical = ethicalP;
      // obj.genericGst = genericGst;
      obj.coolChainGst = coolChainGst;
      obj.ethicalGst = ethicalGst;
      obj.prepaid = prepaid.length;
      obj.prepaidInven = prepaidInven.length

      if (obj.price < 2000) {
        removeInventoryLists.push(each.inventory_id)
      }
      if (sellerDetails.length === 0) {
        sellerDetails.push(obj)

      }
      else {
        let index = sellerDetails.findIndex(e => e.user_id === each.user_id)
        if (index === -1) {
          sellerDetails.push(obj)
        }
      }
    })
  }

  let deliveryTotal = []
  let totalDeliChrgInCart = 0
  sellerDetails.map((data, index) => {
    if (users.isDeliveryCharge === true) {
      deliveryTotal.push(Number(globalDeliveryCrg.value))
    }
    if (data.ethical && data.ethical > 0 && users.isDeliveryCharge === true) {
      totalDeliChrgInCart = totalDeliChrgInCart + Number(globalDeliveryCrg.value)
    }
    if (data.coolChain && data.coolChain > 0 && users.isDeliveryCharge === true) {
      totalDeliChrgInCart = totalDeliChrgInCart + Number(globalDeliveryCrg.value)
    }
    // if (data.generic && data.generic > 0 && users.isDeliveryCharge === true) {
    //   totalDeliChrgInCart = totalDeliChrgInCart + Number(globalDeliveryCrg.value)
    // }
  })

  let minOrderErrorArray = []
  let noMinOrderErrorArray = []

  sellerDetails.map(data => {
    if (data.price < 2000) {
      minOrderErrorArray.push({ price: data.price, Id: data.user_id })
    }
    else if (data.price > 2000) {
      noMinOrderErrorArray.push(data.price)
    }
  })

  let sellerId = [];
  sellerDetails.map((data2, index) => {
    sellerId.push({
      ID: data2.user_id,
      name: data2.seller_name,
      price: data2.price,
      gst: data2.gst1,
      ethical: data2.ethical,
      coolChain: data2.coolChain,
      // generic: data2.generic,
      ethicalGst: data2.ethicalGst,
      coolChainGst: data2.coolChainGst,
      // genericGst: data2.genericGst,
      prepaid: data2.prepaid,
      prepaidInven: data2.prepaidInven
    })
  })
  sellerId.sort((a, b) => a.name.localeCompare(b.name));

  let showGlobalBnpl = sellerId.filter(e => ((e.coolChain > 0 && e.coolChain < 5000) || (e.ethical > 0 && e.ethical < 5000) || (e.generic > 0 && e.generic < 5000)));

  return (
    <BuyerContainer>
      <div className="app-wrapper">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <ContainerHeader match={props.match} title="Shopping Cart" />
          </div>
          <div className=" col-xl-9 col-lg-9 col-md-8 col-sm-8">
            {
              users ?
                <DeliveryAddress userDataFromParent={users} />
                :
                <br />
            }
            {
              sellerId.map((eachId, index) => {
                one = data.filter((val) => val.sellerID === eachId.ID)
                let ethical = one.filter(e => (e.inventory.medicineTypeName === 'Ethical branded' || e.inventory.medicineTypeName === 'Others' || e.inventory.medicineTypeName === 'Generic' || e.inventory.medicineTypeName === 'OTC' || e.inventory.medicineTypeName === 'Surgical'));
                let coolChain = one.filter(e => e.inventory.medicineTypeName === 'Cool chain');
                // let generic = one.filter(e => (e.inventory.medicineTypeName === 'Generic' || e.inventory.medicineTypeName === 'OTC' || e.inventory.medicineTypeName === 'Surgical'));
                return (
                  <div className="jr-card">
                    <div className="animated slideInUpTiny animation-duration-3">
                      <div className="row">
                        <div className=" col-xl-9 col-lg-9 col-md-9 col-sm-9">
                          <NavLink className="buyerRedirectNavlink" to={`/view-seller/${one[0].sellerID}`}>
                            <h3>{one[0].seller_id} ({one.length} {one.length === 1 ? "item" : "items"})<br />
                              <span className={activeButton(eachId) ? 'more2000' : 'less2000'}>₹{(eachId.price).toFixed(2)}</span>&nbsp;<span style={{ fontSize: 'smaller' }} >*excluding GST</span></h3>
                          </NavLink>
                        </div>
                        {activeButton(eachId) ?
                          <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{ textAlign: "end" }}>
                            <button style={{ padding: '10px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} key={index}
                              onClick={(e) => individualCheckout(eachId)}>Seller CheckOut
                            </button>
                          </div> :
                          <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{ textAlign: "end" }}>
                            <button style={{ padding: '10px', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: 'default' }} key={index}>Seller CheckOut
                            </button>
                          </div>
                        }
                      </div>
                      <hr />
                      {coolChain.length > 0 &&
                        <React.Fragment>
                          <div style={borderCoolChain} >
                            <div className="row">
                              <div className=" col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                <h3 className='ml-3' style={fontS}>Cool Chain &nbsp;
                                  {short('coolChain', eachId) &&
                                    <span className='less2000' style={{ fontWeight: 'initial' }}>(Short of ₹{((coolChainData && coolChainData.minOrder) - eachId.coolChain).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>}
                                </h3>
                              </div>
                              {eachId.coolChain >= (coolChainData && coolChainData.minOrder) ?
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} key={index}
                                    onClick={(e) => medicineFunction(eachId, 'cool', coolChain)}>
                                    CheckOut
                                  </button>
                                </div> :
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: 'default' }} key={index}>CheckOut
                                  </button>
                                </div>
                              }
                            </div>
                            {coolChain && coolChain.map((data, index) => {
                              let expDateC = new Date(moment(data.inventory.expiry_date)).getTime();
                              if (data.inventory.isDeleted === false && data.inventory.isHidden === false && data.sellerStatus === 'active' && data.sellerVac === false) {
                                singleItemTotalPrice = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity) * data.inventory.ePTR) : data.inventory.ePTR * data.quantity  //data.price * data.quantity
                                GST = data.inventory.ePTR / 100 * Number(data.gstPercentage)
                                singleItemTotalGST = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity)) * GST : GST * data.quantity
                                AllItemsTotalPrice = AllItemsTotalPrice + singleItemTotalPrice
                                AllItemsTotalGST = AllItemsTotalGST + singleItemTotalGST
                                if (data.discount && data.discount.type === 'Discount') {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * Number(data.gstPercentage)
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && (data.discount.type === 'SameAndDiscount' || data.discount.type === 'DifferentAndDiscount')) {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * data.gstPercentage
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && data.discount.type !== 'Discount') {
                                  finalGST = finalGST + singleItemTotalGST
                                }
                                total = AllItemsTotalPrice + AllItemsTotalGST
                                return (
                                  <CartItems
                                    handleRem={handleRemoveFromCart}
                                    data={data}
                                    index={index}
                                    key={index}
                                    singleItemTotalPrice={singleItemTotalPrice}
                                    GST={GST}
                                    singleItemTotalGST={singleItemTotalGST}
                                    AllItemsTotalPrice={AllItemsTotalPrice}
                                    AllItemsTotalGST={AllItemsTotalGST}
                                    priceAfterDiscount={priceAfterDiscount}
                                    newGST={newGST}
                                    discountMinus={discountMinus}
                                    color={borderCoolChain.c}
                                  />
                                )
                              }
                            }
                            )}
                          </div><hr />
                        </React.Fragment>
                      }
                      {ethical.length > 0 &&
                        <React.Fragment>
                          <div style={borderEthical} >
                            <div className="row">
                              <div className=" col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                <h3 className='ml-3' style={fontS}>{'Medical and Surgical'}&nbsp;
                                  {short('ethicalBranded', eachId) &&
                                    <span className='less2000' style={{ fontWeight: 'initial' }}>(Short of ₹{((ethicalData && ethicalData.minOrder) - eachId.ethical).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>}
                                </h3>
                              </div>
                              {eachId.ethical >= (ethicalData && ethicalData.minOrder) ?
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} key={index}
                                    onClick={(e) => medicineFunction(eachId, 'ethical', ethical)}>CheckOut
                                  </button>
                                </div> :
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: 'default' }} key={index}>CheckOut
                                  </button>
                                </div>
                              }
                            </div>
                            {ethical && ethical.map((data, index) => {
                              let expDateE = new Date(moment(data.inventory.expiry_date)).getTime();
                              if (data.inventory.isDeleted === false && data.inventory.isHidden === false && data.sellerStatus === 'active' && data.sellerVac === false) {
                                singleItemTotalPrice = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity) * data.inventory.ePTR) : data.inventory.ePTR * data.quantity
                                GST = data.inventory.ePTR / 100 * Number(data.gstPercentage)
                                singleItemTotalGST = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity)) * GST : GST * data.quantity
                                AllItemsTotalPrice = AllItemsTotalPrice + singleItemTotalPrice
                                AllItemsTotalGST = AllItemsTotalGST + singleItemTotalGST
                                if (data.discount && data.discount.type === 'Discount') {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * Number(data.gstPercentage)
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && (data.discount.type === 'SameAndDiscount' || data.discount.type === 'DifferentAndDiscount')) {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * data.gstPercentage
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && data.discount.type !== 'Discount') {
                                  finalGST = finalGST + singleItemTotalGST
                                }
                                total = AllItemsTotalPrice + AllItemsTotalGST
                                return (
                                  <CartItems
                                    handleRem={handleRemoveFromCart}
                                    data={data}
                                    index={index}
                                    key={index}
                                    singleItemTotalPrice={singleItemTotalPrice}
                                    GST={GST}
                                    singleItemTotalGST={singleItemTotalGST}
                                    AllItemsTotalPrice={AllItemsTotalPrice}
                                    AllItemsTotalGST={AllItemsTotalGST}
                                    priceAfterDiscount={priceAfterDiscount}
                                    newGST={newGST}
                                    discountMinus={discountMinus}
                                    color={borderEthical.c}
                                  />
                                )
                              }
                            }
                            )}
                          </div><hr />
                        </React.Fragment>
                      }
                      {/* {generic.length > 0 &&
                        <React.Fragment>
                          <div style={borderGeneric} >
                            <div className="row">
                              <div className=" col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                <h3 className='ml-3' style={fontS}>{'Generic & Surgical'}&nbsp;
                                  {short('generic', eachId) &&
                                    <span className='less2000' style={{ fontWeight: 'initial' }}>(Short of ₹{((genericData && genericData.minOrder) - eachId.generic).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>}
                                </h3>
                              </div>
                              {eachId.generic >= (genericData && genericData.minOrder) ?
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} key={index}
                                    onClick={(e) => medicineFunction(eachId, 'generic', generic)}>CheckOut
                                  </button>
                                </div> :
                                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4" style={{ textAlign: "end" }}>
                                  <button className='mr-3' style={{ padding: '10px', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: 'default' }} key={index}>CheckOut
                                  </button>
                                </div>
                              }
                            </div>
                            {generic && generic.map((data, index) => {
                              let expDateG = new Date(moment(data.inventory.expiry_date)).getTime();
                              if (data.inventory.isDeleted === false && data.inventory.isHidden === false && data.sellerStatus === 'active' && data.sellerVac === false) {
                                singleItemTotalPrice = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity) * data.inventory.ePTR) : data.inventory.ePTR * data.quantity
                                GST = data.inventory.ePTR / 100 * Number(data.gstPercentage)
                                singleItemTotalGST = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity)) * GST : GST * data.quantity
                                AllItemsTotalPrice = AllItemsTotalPrice + singleItemTotalPrice
                                AllItemsTotalGST = AllItemsTotalGST + singleItemTotalGST
                                if (data.discount && data.discount.type === 'Discount') {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * Number(data.gstPercentage)
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && (data.discount.type === 'SameAndDiscount' || data.discount.type === 'DifferentAndDiscount')) {
                                  discountMinus = singleItemTotalPrice / 100 * data.discount.discount_per
                                  priceAfterDiscount = singleItemTotalPrice - discountMinus
                                  newGST = priceAfterDiscount / 100 * data.gstPercentage
                                  finalGST = finalGST + newGST
                                  totalDiscountMinus = totalDiscountMinus + discountMinus
                                }
                                if (data.discount && data.discount.type !== 'Discount') {
                                  finalGST = finalGST + singleItemTotalGST
                                }
                                total = AllItemsTotalPrice + AllItemsTotalGST
                                return (
                                  <CartItems
                                    handleRem={handleRemoveFromCart}
                                    data={data}
                                    index={index}
                                    key={index}
                                    singleItemTotalPrice={singleItemTotalPrice}
                                    GST={GST}
                                    singleItemTotalGST={singleItemTotalGST}
                                    AllItemsTotalPrice={AllItemsTotalPrice}
                                    AllItemsTotalGST={AllItemsTotalGST}
                                    priceAfterDiscount={priceAfterDiscount}
                                    newGST={newGST}
                                    discountMinus={discountMinus}
                                    color={borderGeneric.c}
                                  />
                                )
                              }
                            }
                            )}
                          </div><hr />
                        </React.Fragment>
                      } */}
                    </div>
                  </div>)
              })}
          </div>
          <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4 mt-3'>
            <div className="shadow border-0 bg-white text-black card">
              <div className="card-body">
                <h3 className="font-weight-bold ">Order Summary</h3>
                <hr />
                <table style={{ width: '100%' }}>
                  {users && <tr class="mb-1">
                    <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                    <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>
                      MNY {(users.wallet_balance).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                  </tr>}
                  <tr class="mb-1">
                    <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Items Total:</td>
                    <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>
                      ₹{(AllItemsTotalPrice).toFixed(2)}
                    </td>
                  </tr>
                  <tr class="mb-1">
                    <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Total GST:</td>
                    <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>₹
                      {(AllItemsTotalGST).toFixed(2)}</td>
                  </tr>
                  {totalDeliChrgInCart > 0 ?
                    <tr class="mb-1">
                      <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Delivery Charge:</td>
                      <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>₹{totalDeliChrgInCart.toFixed(2)}</td>
                    </tr> : ''
                  }
                  {totalDeliChrgInCart > 0 ?
                    <tr class="mb-1">
                      <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Invoice Total:</td>
                      <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>
                        ₹{(AllItemsTotalPrice + AllItemsTotalGST + totalDeliChrgInCart).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </td>
                    </tr> :
                    <tr class="mb-1">
                      <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Invoice Total:</td>
                      <td className="h4  mt-1 mb-1" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>
                        ₹{(AllItemsTotalPrice + AllItemsTotalGST).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </td>
                    </tr>
                  }
                  {users.wallet_balance > 0 && selectedValue === 'Online' ?
                    <tr class="mb-1">
                      <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                      <td className="h3  mt-1 text-success" style={{ float: 'right', justifyContent: 'end', padding: 0 }}>
                        {Number(total + totalDeliChrgInCart) > Number(users && users.wallet_balance) ?
                          <React.Fragment>
                            -₹{Number(users && users.wallet_balance).toFixed(2)}
                          </React.Fragment> :
                          Number(users && users.wallet_balance) === 0 ?
                            <React.Fragment>
                              ₹{0}
                            </React.Fragment> :
                            <React.Fragment>
                              -₹{(Number(total + totalDeliChrgInCart)).toFixed(2)}
                            </React.Fragment>
                        }
                      </td>
                    </tr> :
                    users.wallet_balance < 0 && selectedValue === 'Online' ?
                      <tr height='25px'>
                        <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                        <td className="h3  mt-2 text-success" style={{ textAlign: 'end', padding: 0 }}>
                          <React.Fragment>
                            +₹{Math.abs(users && users.wallet_balance).toFixed(2)}
                          </React.Fragment>
                        </td>
                      </tr>
                      : null
                  }
                </table>
                <hr />
                <table style={{ width: '100%' }}>
                  <tr>
                    <td className="font-weight-bold h3 text-maroon" >Total:</td>
                    <td className="font-weight-bold h3 text-red mt-1" style={{ textAlign: 'right', float: 'right', padding: 0 }}>
                      {selectedValue === 'Online' && Number(users && users.wallet_balance) > Number(total + totalDeliChrgInCart) ?
                        <React.Fragment>
                          ₹{0}
                        </React.Fragment> :
                        selectedValue === 'Online' && Number(users && users.wallet_balance) < Number(total + totalDeliChrgInCart) ?
                          <React.Fragment>
                            ₹{(Number(total + totalDeliChrgInCart) - Number(users && users.wallet_balance)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                          </React.Fragment> :
                          selectedValue !== 'Online' ?
                            <React.Fragment>
                              ₹{Number(total + totalDeliChrgInCart).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                            </React.Fragment> : ''
                      }
                    </td>
                  </tr>
                </table>
                <hr />
                <h3 className="font-weight-bold ">Payment Method</h3>
                <hr />
                {users && users.cod && users.cod === true && users.codService && users.codService === true && globalCod && globalCod.key === 'globalCod' && globalCod.value === '1' && isCoolChain.length === 0 && isPrepaid.length < 1 && prepaidInvenAll.length === 0 && (totalDeliverd >= 10 || (totalOpenCOD < 5 && ((total + codTotalAmount) < 35000))) ?
                  <Row>
                    <Col md={9} xl={9} lg={9} sm={9} xs={9} className="mt-2">
                      <h4>Cash on Delivery:</h4>
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                      <Radio color="primary" checked={selectedValue === 'COD'} onChange={handleRadioChange} value="COD" name="radio button demo" aria-label="A" />
                    </Col>
                  </Row>
                  : ''
                }
                {!asAdmin ?
                  <Row>
                    <Col md={9} xl={9} lg={9} sm={9} xs={9} className="mt-2">
                      <h4>Pay Now:</h4>
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                      <Radio color="primary" checked={selectedValue === 'Online'} onChange={handleRadioChange} value="Online" name="radio button demo" aria-label="B" />
                    </Col>
                  </Row>
                  : ''}
                {(showGlobalBnpl.length === 0 && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal > Number(total + totalDeliChrgInCart)) ?
                  <Row>
                    <Col md={9} xl={9} lg={9} sm={9} xs={9} className="mt-2">
                      <span>
                        <h4 className='mb-0'>BNPL :</h4>
                        <span> Available limit: </span> <span style={{ color: 'green' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                      </span>
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                      <Radio color="primary" checked={selectedValue === 'BNPL'} onChange={handleRadioChange} value="BNPL" name="radio button demo" aria-label="C" />
                    </Col>
                  </Row> :
                  (showGlobalBnpl.length === 0 && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal < Number(total + totalDeliChrgInCart)) ?
                    <Row>
                      <Col md={9} xl={9} lg={9} sm={9} xs={9} className="mt-2">
                        <h4 className='mb-0'>BNPL :</h4>
                        <span> Available limit: </span> <span style={{ color: 'green' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                      </Col>
                      <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                        <Radio color="primary" checked={selectedValue === 'BNPL'} disabled={true} value="BNPL" name="radio button demo" aria-label="C" />
                      </Col>
                    </Row> : ''
                }
                <br />
                {
                  users && cartDetails && cartDetails.detail && cartDetails.detail.length !== 0 && selectedValue === 'BNPL' ?
                    <button style={{ padding: '10px', width: '100%', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} disabled={isLoading}
                      onClick={(e) => setOtpModel(true)}>
                      Confirm Your Order
                    </button>
                    :
                    users ?
                      cartDetails && cartDetails.detail && cartDetails.detail.length !== 0 && selectedValue === 'COD' ?
                        <button style={{ padding: '10px', width: '100%', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} disabled={isLoading}
                          onClick={handleOnlineModal}>
                          Confirm Your Order
                        </button>
                        :
                        users && cartDetails && cartDetails.detail && cartDetails.detail.length !== 0 && selectedValue === 'Online' ?
                          <button style={{ padding: '10px', width: '100%', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} disabled={isLoading}
                            onClick={() => setOpenNewPopup(true)}>
                            Confirm Your Order
                          </button>
                          :
                          users &&
                            cartDetails && cartDetails.detail && cartDetails.detail.length !== 0 && selectedValue === '' ?
                            <button style={{ padding: '10px', width: '100%', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: "pointer" }}>
                              Select Payment Method
                            </button>
                            :
                            <NavLink className="buyerRedirectNavlink" to={{ pathname: '/', state: '/' }}>
                              <button style={{ padding: '10px', width: '100%', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: "pointer" }}>
                                Add some items
                              </button>
                            </NavLink>
                      :
                      isUserLoggedIn === null ?
                        <NavLink className="buyerRedirectNavlink" to={{ pathname: '/login', state: '/MyCart' }}>
                          <button style={{ padding: '10px', width: '100%', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }}>
                            Sign In To Continue
                          </button>
                        </NavLink>
                        :
                        ''
                }
              </div>
            </div>
          </div>

          <Dialog open={openModal === true} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
            <DialogContent className="mb-1" style={{ overflow: "hidden" }}>
              <MyCartModalError errorData={errorData} />
              {!LicenseErr ? <h4 className="mt-1">Do you accept the above changes?</h4> : ''}
            </DialogContent>
            <DialogActions className="pr-4">
              <React.Fragment>
                {!LicenseErr ? <Button onClick={handleClose} color='secondary'>No</Button> : ''}
                {LicenseErr ? <Button type="submit" color='primary' onClick={handleClose}>Ok</Button> : <Button type="submit" color='primary' onClick={yesShowCart}>Yes, update cart</Button>}
              </React.Fragment>
            </DialogActions>
          </Dialog>

          <Dialog open={minOrderError === true} onClose={handleMinOrderErrorClose} fullWidth={true}>
            <DialogContent style={{ overflow: "hidden" }}>
              <DialogTitle className="" id="alert-dialog-title" style={{ textAlign: "center" }}>
                <span className="text-danger mb-3">
                  <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x"></i>
                </span>
              </DialogTitle>
              <h4 className="font-weight mt-3 mb-5">You have not crossed the minimum order price of ₹2000 for the following sellers:</h4>
              {sellerDetails.map((data) => {
                return (
                  <tr >
                    <td className="h4 mt-1" style={{ float: 'left', justifyContent: 'end', marginBottom: "0px", cursor: 'pointer' }}>
                      {data.price < 2000 ?
                        data.discount_type === "Discount" && data.discount_per ?
                          <React.Fragment>
                            {(j++) + ". Seller Name: "}
                            <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.user_id}`}>
                              {data && data.seller_name}
                            </NavLink>
                            {" ₹" + (2000 - (data.price - (data.price / 100 * Number(data.discount_per)))).toFixed(2) + " short of ₹2000"}
                          </React.Fragment> : <React.Fragment>
                            {(j++) + ". Seller Name: "}
                            <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.user_id}`}>
                              {data && data.seller_name}
                            </NavLink>
                            {" ₹" + (2000 - data.price).toFixed(2) + " short of ₹2000"}
                          </React.Fragment> :
                        ' '
                      }
                    </td>
                  </tr>
                )
              })}
              {
                noMinOrderErrorArray.length < 1 ?
                  <h4 className="font-weight mt-5 mb-3">
                    Please continue shopping and add more products offered by these sellers.
                  </h4>
                  :
                  <h4 className="font-weight mt-5 mb-3">
                    Please continue shopping and add more products offered by these sellers, or place this order for products excluding the ones offered by these sellers.
                  </h4>
              }
            </DialogContent>
            <DialogActions className="pr-4">
              <Button onClick={handleMinOrderErrorClose} color='secondary'>Continue shopping</Button>
              {
                noMinOrderErrorArray.length < 1 ?
                  null
                  :
                  <Button type="submit" color='primary' onClick={() => minOrderErrorProceed(removeInventoryLists)}>Place Order</Button>
              }
            </DialogActions>
          </Dialog>

          <Dialog open={minOrderErrorIndividual === true} onClose={handleMinOrderErrorClose} fullWidth={true}>
            <DialogContent style={{ overflow: "hidden" }}>
              <DialogTitle className="" id="alert-dialog-title" style={{ textAlign: "center" }}>
                <span className="text-danger mb-3">
                  <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x"></i>
                </span>
              </DialogTitle>
              <h4 className="font-weight mt-3 mb-5">You have not crossed the minimum order price of ₹2000 for the following seller:</h4>
              {sellerDetails.map((data) => {
                return (
                  data.user_id === individualSeller.ID ?
                    <tr >
                      <td className="h4 mt-1" style={{ float: 'left', justifyContent: 'end', marginBottom: "0px", cursor: 'pointer' }}>
                        {data.price < 2000 ?
                          data.discount_type === "Discount" && data.discount_per ?
                            <React.Fragment>
                              {"Seller Name: "}
                              <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.user_id}`}>
                                {data && data.seller_name}
                              </NavLink>
                              {" ₹" + (2000 - (data.price - (data.price / 100 * Number(data.discount_per)))).toFixed(2) + " short of ₹2000"}
                            </React.Fragment> : <React.Fragment>
                              {"Seller Name: "}
                              <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.user_id}`}>
                                {data && data.seller_name}
                              </NavLink>
                              {" ₹" + (2000 - data.price).toFixed(2) + " short of ₹2000"}
                            </React.Fragment> :
                          ' '
                        }
                      </td>
                    </tr>
                    : '')
              })}
              <h4 className="font-weight mt-5 mb-3">
                Please continue shopping and add more products offered by this seller.
              </h4>
            </DialogContent>
            <DialogActions className="pr-4">
              <Button onClick={handleMinOrderErrorClose} color='secondary'>Continue shopping</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={open} onClose={handleOnlineModalClose} fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle className='pb-0'>
              Checkout
            </DialogTitle>
            <DialogContent>
              <p style={{ marginBottom: '2px', textAlign: 'left', fontWeight: 'bold' }}>
                "Please note that our company treats Return / Non-acceptance of orders by customers very strictly. In such events, COD facility shall be withdrawn and delivery charges might be applicable on subsequent orders of such customers.<br />
                In case of any disagreement with courier personnel or issue with the Order, please contact our support team at +91 9321927004"</p>
              <DialogContentText>
                {'Are you sure you want to checkout ?'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOnlineModalClose} color='secondary' >
                Cancel
              </Button>
              <Button color='primary' disabled={isLoading} onClick={() => setOpenNewPopup(true)} >
                Order
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openNewPopup} onClose={handleOnlineModalClose} fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle className='pb-0 text-danger'>
              MediWallet Notice:
            </DialogTitle>
            <DialogContent className='text-justify'>
              <p style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                Any  adjustment in prepaid or COD order value either directly, or indirectly through tickets would be credited to Mediwallet,
                which can be utilized for payments towards future prepaid orders. No encashment,
                bank/UPI transfers are allowed for Mediwallet credits except for the case where you choose to close MEDIMNY account.</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOnlineModalClose} color='secondary' >
                I decline & Cancel
              </Button>
              <Button color='primary' disabled={isLoading} onClick={() => {
                if (mediTypeData) {
                  handleMedicineOrder()
                } else {
                  handleOnlineOrder()
                }
              }}>
                I understand and accept
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={individualPop} onClose={handleOnlineModalClose} fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle style={{ paddingBottom: '0px' }}>
              Checkout: {individualSeller.name}
            </DialogTitle>
            <p style={{ marginBottom: '2px', textAlign: 'left', padding: '0px 25px', fontWeight: 'bold' }}>
              "Please note that our company treats Return / Non-acceptance of orders by customers very strictly. In such events, COD facility shall be withdrawn and delivery charges might be applicable on subsequent orders of such customers.<br />
              In case of any disagreement with courier personnel or issue with the Order, please contact our support team at +91 9321927004"</p>
            <DialogContent className='pt-0'>
              <DialogContentText style={{ marginBottom: '0px' }}>
                {'Are you sure you want to checkout ?'}<br />
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-11 ' style={{ paddingLeft: '0px' }}>
                  <table style={{ width: '100%' }}>
                    {users && <tr>
                      <td className="h4" style={{ justifyContent: 'start' }}>MediWallet:</td>
                      <td className="h3 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        MNY {(users.wallet_balance).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                    </tr>}
                    <tr height='25px'>
                      <td className="h4" style={{ justifyContent: 'start' }}>Items Total:</td>
                      <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        ₹{individualSeller && (individualSeller.price).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </td>
                    </tr>
                    <tr height='25px'>
                      <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Total GST:</td>
                      <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        ₹{(individualSeller && individualSeller.gst).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                    </tr>
                    {deliChrg > 0 ?
                      <tr height='25px'>
                        <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Delivery Charge:</td>
                        <td className="h4  mt-2" style={{ justifyContent: 'end', padding: 0 }}>₹{deliChrg.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr> : ''
                    }
                    {deliChrg > 0 ?
                      <tr height='25px'>
                        <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Invoice Total:</td>
                        <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                          ₹{((individualSeller.price) + (individualSeller.gst) + deliChrg).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr> :
                      <tr height='25px'>
                        <td className="h4" >Invoice Total:</td>
                        <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                          ₹{((individualSeller.price) + (individualSeller.gst)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr>
                    }
                    {users.wallet_balance > 0 && selectedValue === 'Online' ?
                      <tr height='25px'>
                        <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                        <td className="h3  mt-2 text-success" style={{ justifyContent: 'end', padding: 0 }}>
                          {Number((Number(individualSeller.gst) + Number(individualSeller.price)) + deliChrg) > Number(users && users.wallet_balance) ?
                            <React.Fragment>
                              -₹{Number(users && users.wallet_balance).toFixed(2)}
                            </React.Fragment> :
                            Number(users && users.wallet_balance) === 0 ?
                              <React.Fragment>
                                ₹{0}
                              </React.Fragment> :
                              <React.Fragment>
                                -₹{(Number((individualSeller.gst) + Number(individualSeller.price) + deliChrg)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                              </React.Fragment>
                          }
                        </td>
                      </tr> :
                      users.wallet_balance < 0 && selectedValue === 'Online' ?
                        <tr height='25px'>
                          <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                          <td className="h3  mt-2 text-success" style={{ justifyContent: 'end', padding: 0 }}>
                            <React.Fragment>
                              +₹{Math.abs(users && users.wallet_balance).toFixed(2)}
                            </React.Fragment>
                          </td>
                        </tr>
                        : null
                    }
                    <tr height='25px'>
                      <td className="font-weight-bold h4 text-maroon" >Total:</td>
                      <td className="font-weight-bold h4 text-red" style={{ textAlign: 'left', padding: 0 }}>
                        {selectedValue === 'Online' && Number(users && users.wallet_balance) > Number((individualSeller.gst) + individualSeller.price) + deliChrg ?
                          <React.Fragment>
                            ₹{0}
                          </React.Fragment> :
                          selectedValue === 'Online' && Number(users && users.wallet_balance) < Number(((individualSeller.gst) + individualSeller.price) + deliChrg) ?
                            <React.Fragment>
                              ₹{(Number(((individualSeller.gst) + individualSeller.price) + deliChrg) - Number(users && users.wallet_balance)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                            </React.Fragment> :
                            selectedValue !== 'Online' ?
                              <React.Fragment>
                                ₹{Number(((individualSeller.gst) + individualSeller.price) + deliChrg).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                              </React.Fragment> : ''
                        }
                      </td>
                    </tr>
                  </table>
                </div>
                <hr />
                <h4 className="font-weight-bold mb-0 mt-3">Payment Method</h4>
                {users && users.cod && users.cod === true && users.codService && users.codService === true && globalCod && globalCod.key === 'globalCod' && globalCod.value === '1' && individualSeller.coolChain === 0 && individualSeller.prepaid === 0 && individualSeller.prepaidInven === 0 && (totalDeliverd >= 10 || (totalOpenCOD < 5 && ((checkOutVal + codTotalAmount) < 35000))) ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-3">
                      <h4 className="mb-0">Cash on Delivery:</h4>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                      <Radio color="primary" checked={selectedValue === 'COD'} onChange={handleRadioChange} value="COD" name="radio button demo" aria-label="A" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row>
                  : ''
                }
                {!asAdmin ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                      <h4>Pay Now:</h4>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                      <Radio color="primary" checked={selectedValue === 'Online'} onChange={handleRadioChange} value="Online" name="radio button demo" aria-label="B" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row>
                  : ''}
                {(Number(individualSeller.price) > 5000 && individualSeller.bnplOption === true && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal > Number(individualSeller.price)) ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                      <h4 className='mb-0'>BNPL :</h4>
                      <span style={{ fontSize: '14px', color: 'black' }}> Available limit: </span> <span style={{ color: 'green', fontSize: '14px' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                      <Radio color="primary" checked={selectedValue === 'BNPL'} onChange={handleRadioChange} value="BNPL" name="radio button demo" aria-label="C" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row> :
                  (Number(individualSeller.price) > 5000 && individualSeller.bnplOption === true && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal < Number(individualSeller.price)) ?
                    <Row>
                      <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                        <h4 className='mb-0'>BNPL :</h4>
                        <span style={{ fontSize: '14px', color: 'black' }}> Available limit: </span> <span style={{ color: 'green', fontSize: '14px' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                      </Col>
                      <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                        <Radio color="primary" checked={selectedValue === 'BNPL'} disabled={true} value="BNPL" name="radio button demo" aria-label="C" />
                      </Col>
                      <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                      </Col>
                    </Row> : ''
                }
              </DialogContentText>
              <DialogActions style={{ paddingRight: '0px' }}>
                <Button onClick={handleOnlineModalClose} style={{ paddingRight: '0px' }} color='secondary' >
                  Cancel
                </Button>
                {selectedValue === 'BNPL' ?
                  <Button color='primary' disabled={isLoading} onClick={(e) => setOtpModel(true)} >
                    Order
                  </Button> :
                  selectedValue === 'COD' ?
                    <Button color='primary' disabled={isLoading} onClick={handleOnlineModal} >
                      Order
                    </Button> :
                    selectedValue === 'Online' ?
                      <Button color='primary' disabled={isLoading} onClick={() => setOpenNewPopup(true)} >
                        Order
                      </Button> :
                      selectedValue !== '' ?
                        <Button color='primary' disabled={isLoading} onClick={handleOnlineOrder} >
                          Order
                        </Button> :
                        <Button color='primary' style={{ cursor: "pointer", paddingRight: '0px' }}  >
                          Select Payment Method
                        </Button>
                }
              </DialogActions>
            </DialogContent>

          </Dialog>
          <Dialog open={medicinePop} onClose={handleOnlineModalClose} fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle style={{ paddingBottom: '0px' }}>
              Checkout: {`${mediTypeData.name} (${mediTypeData.key})`}
            </DialogTitle>
            <p style={{ marginBottom: '2px', textAlign: 'left', padding: '0px 25px', fontWeight: 'bold' }}>
              "Please note that our company treats Return / Non-acceptance of orders by customers very strictly. In such events, COD facility shall be withdrawn and delivery charges might be applicable on subsequent orders of such customers.<br />
              In case of any disagreement with courier personnel or issue with the Order, please contact our support team at +91 9321927004"</p>
            <DialogContent className='pt-0'>
              <DialogContentText style={{ marginBottom: '0px' }}>
                {'Are you sure you want to checkout ?'}<br />
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-11 ' style={{ paddingLeft: '0px' }}>
                  <table style={{ width: '100%' }}>
                    {users && <tr>
                      <td className="h4" style={{ justifyContent: 'start' }}>MediWallet:</td>
                      <td className="h3 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        MNY {(users.wallet_balance).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                    </tr>}
                    <tr height='25px'>
                      <td className="h4" style={{ justifyContent: 'start' }}>Items Total:</td>
                      <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        ₹{(mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain :
                          mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical :
                            mediTypeData.key === 'Generic' ? mediTypeData.generic : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </td>
                    </tr>
                    <tr height='25px'>
                      <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Total GST:</td>
                      <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                        ₹{(mediTypeData.key === 'Cool chain' ? mediTypeData.coolChainGst :
                          mediTypeData.key === 'Ethical branded' ? mediTypeData.ethicalGst :
                            mediTypeData.key === 'Generic' ? mediTypeData.genericGst : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                    </tr>
                    {individualDeliveryChrg > 0 ?
                      <tr height='25px'>
                        <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Delivery Charge:</td>
                        <td className="h4  mt-2" style={{ justifyContent: 'end', padding: 0 }}>₹{individualDeliveryChrg.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr> : ''
                    }
                    {individualDeliveryChrg > 0 ?
                      <tr height='25px'>
                        <td className="h4" style={{ padding: 0, justifyContent: 'start' }}>Invoice Total:</td>
                        <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                          ₹{((
                            mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                              mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0)
                            +
                            individualDeliveryChrg).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr> :
                      <tr height='25px'>
                        <td className="h4" >Invoice Total:</td>
                        <td className="h4 mt-2" style={{ justifyContent: 'end', padding: 0 }}>
                          ₹{(
                            mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                              mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                      </tr>
                    }
                    {users.wallet_balance > 0 && selectedValue === 'Online' ?
                      <tr height='25px'>
                        <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                        <td className="h3  mt-2 text-success" style={{ justifyContent: 'end', padding: 0 }}>
                          {Number(Number(
                            mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                              mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg) > Number(users && users.wallet_balance) ?
                            <React.Fragment>
                              -₹{Number(users && users.wallet_balance).toFixed(2)}
                            </React.Fragment> :
                            Number(users && users.wallet_balance) === 0 ?
                              <React.Fragment>
                                ₹{0}
                              </React.Fragment> :
                              <React.Fragment>
                                -₹{(Number(
                                  mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                                    mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                      mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                              </React.Fragment>
                          }
                        </td>
                      </tr> :
                      users.wallet_balance < 0 && selectedValue === 'Online' ?
                        <tr height='25px'>
                          <td className="h4 text-success" style={{ padding: 0, justifyContent: 'start' }}>MediWallet:</td>
                          <td className="h3  mt-2 text-success" style={{ justifyContent: 'end', padding: 0 }}>
                            <React.Fragment>
                              +₹{Math.abs(users && users.wallet_balance).toFixed(2)}
                            </React.Fragment>
                          </td>
                        </tr>
                        : null
                    }
                    <tr height='25px'>
                      <td className="font-weight-bold h4 text-maroon" >Total:</td>
                      <td className="font-weight-bold h4 text-red" style={{ textAlign: 'left', padding: 0 }}>
                        {selectedValue === 'Online' && Number(users && users.wallet_balance) > Number(mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                          mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                            mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg ?
                          <React.Fragment>
                            ₹{0}
                          </React.Fragment> :
                          selectedValue === 'Online' && Number(users && users.wallet_balance) < Number((mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                            mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                              mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg) ?
                            <React.Fragment>
                              ₹{(Number((
                                mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                                  mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                    mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg) - Number(users && users.wallet_balance)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                            </React.Fragment> :
                            selectedValue !== 'Online' ?
                              <React.Fragment>
                                ₹{Number((
                                  mediTypeData.key === 'Cool chain' ? mediTypeData.coolChain + mediTypeData.coolChainGst :
                                    mediTypeData.key === 'Ethical branded' ? mediTypeData.ethical + mediTypeData.ethicalGst :
                                      mediTypeData.key === 'Generic' ? mediTypeData.generic + mediTypeData.genericGst : 0) + individualDeliveryChrg).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                              </React.Fragment> : ''
                        }
                      </td>
                    </tr>
                  </table>
                </div>
                <hr />
                <h4 className="font-weight-bold mb-0 mt-3">Payment Method</h4>
                {users && users.cod && users.cod === true && users.codService && users.codService === true && globalCod && globalCod.key === 'globalCod' && globalCod.value === '1' && !mediTypeData.showCod && !mediTypeData.showCodForProd && !mediTypeData.showCodForInven && (totalDeliverd >= 10 || (totalOpenCOD < 5 && ((prodcheckOutVal + codTotalAmount) < 35000)))
                  ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-3">
                      <h4 className="mb-0">Cash on Delivery:</h4>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                      <Radio color="primary" checked={selectedValue === 'COD'} onChange={handleRadioChange} value="COD" name="radio button demo" aria-label="A" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row>
                  : ''
                }
                {!asAdmin ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                      <h4>Pay Now:</h4>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                      <Radio color="primary" checked={selectedValue === 'Online'} onChange={handleRadioChange} value="Online" name="radio button demo" aria-label="B" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row>
                  : ''}
                {(Number(mediTypeData.price) > 5000 && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal > Number(mediTypeData.price)) ?
                  <Row>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                      <h4 className='mb-0'>BNPL :</h4>
                      <span style={{ fontSize: '14px', color: 'black' }}> Available limit: </span> <span style={{ color: 'green', fontSize: '14px' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </Col>
                    <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                      <Radio color="primary" checked={selectedValue === 'BNPL'} onChange={handleRadioChange} value="BNPL" name="radio button demo" aria-label="C" />
                    </Col>
                    <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                    </Col>
                  </Row> :
                  (Number(mediTypeData.price) > 5000 && users.isBnpl === true && globalBnpl && globalBnpl.value === '1' && bnplBal < Number(mediTypeData.price)) ?
                    <Row>
                      <Col md={5} xl={5} lg={5} sm={5} xs={5} className="mt-2">
                        <h4 className='mb-0'>BNPL :</h4>
                        <span style={{ fontSize: '14px', color: 'black' }}> Available limit: </span> <span style={{ color: 'green', fontSize: '14px' }}>₹{(bnplBal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                      </Col>
                      <Col md={2} xl={2} lg={2} sm={2} xs={2} >
                        <Radio color="primary" checked={selectedValue === 'BNPL'} disabled={true} value="BNPL" name="radio button demo" aria-label="C" />
                      </Col>
                      <Col md={5} xl={5} lg={5} sm={5} xs={5}>
                      </Col>
                    </Row> : ''
                }
              </DialogContentText>
              <DialogActions style={{ paddingRight: '0px' }}>
                <Button onClick={handleOnlineModalClose} style={{ paddingRight: '0px' }} color='secondary' >
                  Cancel
                </Button>
                {selectedValue === 'BNPL' ?
                  <Button color='primary' disabled={isLoading} onClick={(e) => setOtpModel(true)} >
                    Order
                  </Button> :
                  selectedValue === 'COD' ?
                    <Button color='primary' disabled={isLoading} onClick={handleOnlineModal} >
                      Order
                    </Button> :
                    selectedValue === 'Online' ?
                      <Button color='primary' disabled={isLoading} onClick={() => setOpenNewPopup(true)} >
                        Order
                      </Button> :
                      selectedValue !== '' ?
                        <Button color='primary' disabled={isLoading} onClick={handleMedicineOrder} >
                          Order
                        </Button> :
                        <Button color='primary' style={{ cursor: "pointer", paddingRight: '0px' }}  >
                          Select Payment Method
                        </Button>
                }
              </DialogActions>
            </DialogContent>

          </Dialog>

          <Dialog open={acceptModal} onClose={handleAcceptClose} fullWidth={true}>
            <DialogContent style={{ overflow: "hidden" }}>
              <DialogTitle className="" id="alert-dialog-title" style={{ textAlign: "center" }}>
                <span className="text-success mb-3">
                  <i class="zmdi zmdi-check-circle animated fadeInUp zmdi-hc-5x"></i>
                </span>
                <h1 className="mt-4 font-weight-bold">Order Placed Successfully</h1>
              </DialogTitle>
              <h3 style={{ textAlign: "center" }}>
                <p>Your order {`${successData.length > 12 ? `id's:` : 'id:'}`}</p>
                {successData && successData}
              </h3>
            </DialogContent>
            <DialogActions className="pr-4">
              <Button onClick={handleAcceptClose} color='secondary'>Shop More</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={otpModel} onClose={handleCloseOtpModel}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle>
              BNPL Order
              <DialogContentText className="mt-0 mb-0">
                Order will be paid through BNPL, please enter OTP.
              </DialogContentText>
            </DialogTitle>
            <DialogContent>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingTop: '19px' }}>
                  <Button onClick={getOtp} color='primary' variant="outlined" fullWidth={true} disabled={set}>
                    Generate OTP
                  </Button>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <FormGroup>
                    <Label className='p-0 m-0'>Enter OTP</Label>
                    <Input id="otp" name="otp" type="text" placeholder="Insert OTP"
                      onChange={handleChanged}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOtpModel} color='secondary' >
                Cancel
              </Button>
              {otp.length === 6 ?
                <Button onClick={handleOnlineOrder} color='primary'>
                  Place Order
                </Button> :
                <Button color='primary' disabled={true}>
                  Place Order
                </Button>
              }
            </DialogActions>
          </Dialog>

        </div>
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ auth, seller, buyer }) => {
  const { userDetails } = seller;
  const { user_details } = auth;
  const { cartDetails, mediWallet, removeFromCart, addToCart } = buyer;
  let users = userDetails ? userDetails : user_details
  return { user_details, users, cartDetails, mediWallet, removeFromCart, addToCart }
};

export default connect(mapStateToProps, { getUserDetail, getCartDetails, getMediWallet, removeFromCart, emptyCart, addToCart })(MyCart);